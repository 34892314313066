// import ContextProvider from "context/ContextProvider";
import "assets/vendors/animate.css";
import "assets/vendors/custom-animate.css";
import "assets/vendors/flaticon.css";
// import "assets/vendors/fontawesome-all.css";
import "assets/vendors/linoor-icons.css";
import "assets/vendors/reey-font.css";
import "assets/vendors/fa-5.15.4/fa/all.css"
import "assets/vendors/fa-5.15.4/fa/regular.css"
import "bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/swiper/swiper-bundle.min.css";
import "react-circular-progressbar/dist/styles.css";
// import "react-modal-video/css/modal-video.min.css";
import "tiny-slider/dist/tiny-slider.css";

// extra css
import "styles/style.css";
import "styles/hover.css";
import "styles/responsive.css";

const MyApp = ({ Component, pageProps }) => {
  return (
      <Component {...pageProps} />
  );
};

export default MyApp;
